import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Icon } from "@iconify/react";

import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {Book1, BookSquare, Copyright, Edit, Eye, Flag, Messages1, SecuritySafe, Trash} from "iconsax-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTrigger } from "../../../components/ui/alert-dialog";
import { Editor } from "@tinymce/tinymce-react";
import { getByPageName, updateByPageName } from "../../../lib/API";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";

const { Content } = Layout;

export default function Pages() {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate();
  const [is_open, setIs_open] = React.useState(false)

  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [current_page, setCurrent_page] = useState('')


  const [pages, setPages] = useState([]);








  useEffect(() => {
    window.document.title = "الصفحات"

    async function fetchData() {
      try {
            const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}pages`,{
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + token,
                    "Accept-Language": "ar",
                }

            })
            const response = await request.json()
        if (request.status === 200) {
            console.log(response)
            setPages(response.pages)
            setLoading(false)
        }


      } catch (error) {
            console.error(error)
            // dispatch(setIsAuth(false))
            // dispatch(setUserToken(''))
            // navigate('/auth/admin/login')
        }
    }
    fetchData()
  }, [])





  return (
    <>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>

      ) : (
        <>
          <div><Toaster /></div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>


            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div style={{ background: "#f9f9f9" }} className={" p-2 rounded-md"}>
                        <BookSquare size="48" color="#2dacf4" variant="Bulk" />
                      </div>

                      <h1 className={"text-xl mr-2"}>الصفحات المتوفرة</h1>
                    </div>


                  </div>
                </CardHeader>
              </Card>

            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mx-6 mt-6">
              {
                pages.map((page:any, index:any) => {
                  return (
                    <>
                      <Card>
                        <div className="flex items-center my-1 justify-between">
                          <div className="flex items-center">
                            <div
                              style={{ background: "#f9f9f9" }}
                              className={" p-2 rounded-md"}
                            >

                              <Book1 size="32" color="#2dacf4" variant="Bulk"/>
                            </div>

                            <h1 className={"text-xl mr-2"}>{page.slug}</h1>
                          </div>
                          <div>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Icon
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  icon="pepicons-pop:dots-y"
                                />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="w-56">
                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                  <DropdownMenuItem onClick={async function () {
                                    navigate("/admin/editpage",{state:{
                                      slug:page.slug,
                                        id:page.id
                                      }})

                                  }}>
                                    <Edit
                                      color="#475467"
                                      variant="Bulk"
                                      className="ml-2 h-8 w-8"
                                    />
                                    <span>تعديل</span>
                                  </DropdownMenuItem>


                                </DropdownMenuGroup>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      </Card>


                    </>

                  )
                })
              }

            </div>
            <AlertDialog   open={is_open} onOpenChange={setIs_open}>
              <AlertDialogContent>
                <AlertDialogHeader>

                  <h1 className="text-xl text-right">تحديث محتوى الصفحة</h1>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div>
                        <Label htmlFor="titleAr">
                          عنوان الصفحة بالعربي
                        </Label>
                        <Input value={titleAr} onChange={(e) => setTitleAr(e.target.value)} id="titleAr"
                               placeholder="عنوان الصفحة"></Input>
                      </div>
                      <div>
                        <Label htmlFor="titleEn">
                          عنوان الصفحة بالانقليزي
                        </Label>
                        <Input value={titleEn} onChange={(e) => setTitleEn(e.target.value)} id="titleEn"
                               placeholder="عنوان الصفحة"></Input>
                      </div>
                      <Editor
                          apiKey="yytl70c7b5e96ey2zbtm8fopv1huio0fv1837anrnivt4sgz"
                          init={{
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                          }}
                          initialValue={descriptionAr}
                          onChange={(e) => setDescriptionAr(e.target.getContent())}
                          onEditorChange={(e) => setDescriptionAr(e)}
                      />
                    </div>
                    <div>
                      <div>
                        <Label htmlFor="titleAr">
                          عنوان الصفحة بالعربي
                        </Label>
                        <Input value={titleAr} onChange={(e) => setTitleAr(e.target.value)} id="titleAr"
                               placeholder="عنوان الصفحة"></Input>
                      </div>
                      <div>
                        <Label htmlFor="titleEn">
                          عنوان الصفحة بالانقليزي
                        </Label>
                        <Input value={titleEn} onChange={(e) => setTitleEn(e.target.value)} id="titleEn"
                               placeholder="عنوان الصفحة"></Input>
                      </div>
                      <Editor
                          apiKey="yytl70c7b5e96ey2zbtm8fopv1huio0fv1837anrnivt4sgz"
                          init={{
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                          }}
                          initialValue={descriptionAr}
                          onChange={(e) => setDescriptionAr(e.target.getContent())}
                          onEditorChange={(e) => setDescriptionAr(e)}
                      />
                    </div>

                  </div>


                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>الغاء</AlertDialogCancel>
                  <AlertDialogAction onClick={() => updateByPageName(current_page, token, {
                    titleAr,
                    titleEn,
                    descriptionAr,
                    descriptionEn
                  })}>تحديث محتوى الصفحة</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>

          </Content>
        </>
      )}


    </>
  )
}