import {Card, Layout, Pagination} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../Layouts/Main";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/components/ui/card";
import React from "react";
import toast, {Toaster} from "react-hot-toast";

import {HashLoader, PropagateLoader} from "react-spinners";
import {Link, useNavigate} from "react-router-dom";
import {
    Bank,
    Bezier,
    Book,
    BookSquare,
    Category,
    Edit,
    House,
    Setting2,
    Trash,
    User,
    Location,
    Personalcard,
    Eye,
} from "iconsax-react";
import {Button} from "src/components/ui/button";
import {Input} from "src/components/ui/input";
import {Label} from "src/components/ui/label";
import {Switch} from "src/components/ui/switch";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {Icon} from "@iconify/react";

import {setIsAuth, setUserToken} from "src/redux/slices/user_slice";
import {getFetcher} from "src/lib/API";
import {SelectShadncn, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/ui/select";
import {deleteFettcher, putFettcher} from "../../API";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "../../components/ui/alert-dialog";
import {PaginationProps} from "antd/lib";

const {Content} = Layout;

export default function Property() {
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [currentStatusFilter, setCurrentStatusFilter] = useState('all');
    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)


    const status = [
        {
            arabic: "مفعل",
            english: "PUBLISHED",
        },
        {
            arabic: "قيد المراجعة",
            english: "PENDING",
        },
        {
            arabic: "مرفوض",
            english: "REJECTED",
        },
        {
            arabic: "مسودة",
            english: "DRAFT",
        }
    ]

    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        console.log('Page: ', pageNumber);
        setPage(pageNumber)
        setLoading(true)


    };

    useEffect(() => {
        window.document.title = "الوحدات السكنية";

        async function fetchData() {
            try {
                const [propertiesResponse] = await Promise.all([
                    getFetcher(token, `${process.env.REACT_APP_DEV_ADMIN_API_URL}properties?page=${page}&limit=${pageSize}&sort[by]=created_at`),
                ]);

                setData(propertiesResponse.data.items);
                setPage(propertiesResponse.data.pagination.currentPage)
                setPageSize(propertiesResponse.data.pagination.perPage)
                setTotal(propertiesResponse.data.pagination.total)
                setLoading(false);
            } catch (error) {
                console.error(error);
                toast.error("حدث خطأ أثناء تحميل البيانات");
            }
        }

        fetchData();
    }, [page, pageSize,loading]);

    // @ts-ignore

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7"/>
                </div>
            ) : (
                <>
                    <div>
                        <Toaster/>
                    </div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <h1 className="text-xl">


                                            الوحدات السكنية
                                            ( {total} )
                                            وحدة

                                        </h1>

                                        <Button onClick={() => navigate("/admin/property/create")}>
                                            أضافة وحدة جديدة
                                        </Button>
                                    </div>
                                </CardHeader>
                            </Card>
                        </div>
                        <div className="mx-6 mt-6 gap-4 grid grid-cols-2">
                            <div className="flex items-center gap-4">
                                <h1>
                                    بحث
                                </h1>
                                <Input
                                    placeholder="بحث برقم الوحدة السكنية ,, بالأسم"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className="flex items-center gap-2">
                                <h1 className="whitespace-nowrap">
                                    حسب الحالة
                                </h1>
                                <SelectShadncn value={currentStatusFilter} onValueChange={(value) => {
                                    setCurrentStatusFilter(value)

                                }}>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="الحالة"/>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {status.map((s) => (
                                            <SelectItem key={s.english} value={s.english}>
                                                {s.arabic}
                                            </SelectItem>
                                        ))}
                                        <SelectItem value="all">
                                            الكل
                                        </SelectItem>
                                    </SelectContent>
                                </SelectShadncn>

                            </div>

                        </div>
                        <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
                            {data
                                .filter((item: any) =>
                                    item.title.includes(searchQuery) || item.id.toString().includes(searchQuery) ||
                                    status.find((s) => s.english === item.status)?.arabic.includes(searchQuery)
                                )


                                .filter((item: any) => currentStatusFilter === 'all' ? true : item.status === currentStatusFilter)


                                .map((item: any) => (

                                    <Card>
                                        <div className="flex flex-column items-center my-1 justify-between">
                                            <div className="flex items-center">
                                                <div
                                                    style={{background: "#f9f9f9"}}
                                                    className={" p-2 rounded-md"}
                                                >
                                                    <House
                                                        color="#475467"
                                                        variant="Bulk"
                                                        className="h-12 w-12"
                                                    />
                                                </div>

                                                <h1 className={"text-xl font-bold mr-2"}>{item.title}</h1>
                                                <h1 className={`text-lg mr-2 ${item.status === "PUBLISHED" ? "success-text" : item.status === "REJECTED" ? "error-text" : item.status === "DRAFT" ? "info-text" : "pending-text"}`}>
                                                    ( {status.find(s => s.english === item.status)?.arabic} )

                                                </h1>
                                            </div>
                                            <div className="flex items-center gap-4">
                                                <div>
                                                    <SelectShadncn onValueChange={async (value) => {
                                                        const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties/status/${item.id}`, {
                                                            method: 'PUT',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                "Authorization": `Bearer ${token}`,
                                                                "Accept": "application/json"
                                                            },
                                                            body: JSON.stringify({
                                                                    status: value

                                                                }
                                                            )
                                                        })
                                                        if (request.status === 200) {
                                                            toast.success('تم تغيير حالة الوحدة بنجاح')


                                                        }
                                                    }}>
                                                        <SelectTrigger className="w-full">
                                                            <SelectValue
                                                                placeholder={status.find(s => s.english === item.status)?.arabic}/>
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {
                                                                status.map((s) => (
                                                                    <SelectItem key={s.english}
                                                                                value={s.english}>{s.arabic}</SelectItem>

                                                                ))

                                                            }


                                                        </SelectContent>
                                                    </SelectShadncn>
                                                </div>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Icon
                                                            width={24}
                                                            height={24}
                                                            className="cursor-pointer"
                                                            icon="pepicons-pop:dots-y"
                                                        />
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent className="w-56">
                                                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                        <DropdownMenuSeparator/>
                                                        <DropdownMenuGroup>
                                                            <DropdownMenuItem
                                                                onClick={() => navigate(`/admin/property/view/${item.id}`, {state: item})}>
                                                                <Eye
                                                                    color="#475467"
                                                                    variant="Bulk"
                                                                    className="ml-2 h-8 w-8"
                                                                />
                                                                <span>عرض</span>
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem
                                                                onClick={() => navigate("/admin/property/edit", {state: item})}>
                                                                <Edit
                                                                    color="#475467"
                                                                    variant="Bulk"
                                                                    className="ml-2 h-8 w-8"
                                                                />
                                                                <span>تعديل</span>
                                                            </DropdownMenuItem>

                                                            <DropdownMenuItem onClick={() => {
                                                                setIsDeleting(true)
                                                                setSelectedItem(item)

                                                            }}>
                                                                <Trash
                                                                    color="#F04438"
                                                                    variant="Bulk"
                                                                    className="ml-2 h-8 w-8"
                                                                />
                                                                <span>حذف</span>
                                                            </DropdownMenuItem>
                                                        </DropdownMenuGroup>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>


                                        </div>
                                        <div className="flex items-center mr-2">
                                            <h1 className="font-semibold">
                                                رقم الوحدة السكنية :
                                            </h1>
                                            <h1>
                                                {item.id}
                                            </h1>
                                        </div>
                                    </Card>
                                ))
                            }
                        </div>
                        <AlertDialog open={isDeleting}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>هل أنت متأكد من الحذف؟</AlertDialogTitle>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setIsDeleting(false)}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => {
                                        const deleteItem = async () => {
                                            const request = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties/${selectedItem.id}`, {
                                                    method: 'DELETE',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        "Authorization": `Bearer ${token}`,
                                                        "Accept": "application/json"
                                                    }
                                                }
                                            )
                                            if (request.status === 200) {
                                                toast.success('تم حذف الوحدة بنجاح')
                                                setIsDeleting(false)
                                                setData(data.filter((d: any) => d.id !== selectedItem.id))
                                            }
                                        }
                                        deleteItem()
                                    }}>تأكيد الحذف</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                        <div className="flex justify-content-center my-6">
                            <div className="center-pagination">
                                <Pagination
                                    pageSize={pageSize}
                                    showTotal={() => `عرض ${pageSize} من ${total} وحدة`}
                                    current={page}
                                    showQuickJumper
                                    defaultCurrent={1}
                                    total={total}
                                    onShowSizeChange={(current, size) => {
                                        console.log(current, size);
                                        setPageSize(size)
                                    }}
                                    onChange={onChange}
                                />

                            </div>

                        </div>

                    </Content>
                </>
            )}
        </>
    );
}
