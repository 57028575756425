import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  name: string;
  idRef: string;
};
function ExportToPDF({ name, idRef }: Props) {
  const [initWindow, setInitWindow] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setInitWindow(true);
    };
    if (document.readyState === 'complete') {
      onPageLoad();
      return () => null;
    }
    window.addEventListener('load', onPageLoad);
    return () => window.removeEventListener('load', onPageLoad);
  }, []);

  const downloadPdf = useCallback(async () => {
    if (initWindow) {
      const { default: html2pdf } = await import('html2pdf.js');
      const input = document.getElementById(idRef);

      const opt = {
        margin: 0,
        filename: `${name}.pdf`,
        image: { type: 'jpeg', quality: 0.9 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', size: 'A4', orientation: 'landscape' },
      };
      await html2pdf().set(opt).from(input).save();
    }
  }, [initWindow]);

  return (
    <Button
      className="mx-4 h-10 rounded-3xl bg-primary px-10 font-medium"
      type="primary"
      onClick={() => initWindow && downloadPdf()}>
        تصدير الى PDF
    </Button>
  );
}

export default ExportToPDF;
