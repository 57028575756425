import {Card, Checkbox, Input, Layout, Table} from "antd";
import {ArrowCircleRight2, ArrowLeft2, ArrowRight2, Data2, House, Profile2User} from "iconsax-react";
import AdminLayout from "src/Layouts/Main";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader
} from "src/components/ui/alert-dialog";
import {CardContent, CardHeader} from "src/components/ui/card";
import toast, {Toaster} from "react-hot-toast";
import {HashLoader} from "react-spinners";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {setIsAuth, setUserToken} from "src/redux/slices/user_slice";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {ColumnsType} from "antd/es/table";
import {Search} from "lucide-react";
import {Button} from "../../../components/ui/button";
import {Icon} from "@iconify/react";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {tr} from "date-fns/locale";


const {Content} = Layout;

interface Customer {
    id: number;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    total_booking: number;
    total_paid_amount: number;
    total_stayd: number;
    location: string;
}

export default function Customers() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([])
    const [searchText, setSearchText] = useState('')
    const [currentFirstNameSort, setCurrentFirstNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const [currentLastNameSort, setCurrentLastNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)


    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=Customer&page=${page}&limit=${pageSize}&sort[by]=created_at`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                    "Accept-Language": "ar",
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
                setPage(data.data.pagination.currentPage)
                setPageSize(data.data.pagination.perPage)
                setTotal(data.data.pagination.total)
            } else {

            }
        } catch (error) {
            dispatch(setIsAuth(false))
            dispatch(setUserToken(''))
            navigate('/auth/admin/login')
            console.error(error)
        }
    }

    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        window.document.title = "العملاء"

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            fetchData();
        }, 300); // Adjust the delay as needed

        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, [page, pageSize]);

    const itemRender = (_: any, type: string, originalElement: any) => {
        console.log(type, "a");
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333"/>;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333"/>;
        }
        return originalElement;
    };

    const columns: ColumnsType<Customer> = [
        {
            title: "Customer Number",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
            defaultSortOrder: "ascend",
        },
        {
            title: "Unit Number",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: (a, b) => {
                const arabicRegex = /[\u0600-\u06FF]/;
                const aIsArabic = arabicRegex.test(a.first_name);
                const bIsArabic = arabicRegex.test(b.first_name);

                if (currentFirstNameSort === 'descend') {
                    if (a.first_name === null || a.first_name == "") return 1;
                    if (b.first_name === null || b.first_name == "") return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                if (currentFirstNameSort === 'ascend') {
                    if (a.first_name === null) return 1;
                    if (b.first_name === null) return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                return 0; // No sorting
            },
            sortOrder: currentFirstNameSort,
            sortDirections: ['ascend', 'descend', null],
            onHeaderCell: () => ({
                onClick: () => {
                    if (currentFirstNameSort === 'ascend') {
                        setCurrentFirstNameSort('descend');
                    } else if (currentFirstNameSort === 'descend') {
                        setCurrentFirstNameSort(null);
                    } else {
                        setCurrentFirstNameSort('ascend');
                    }
                },
            }),
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
            sorter: (a, b) => {
                const arabicRegex = /[\u0600-\u06FF]/;
                const aIsArabic = arabicRegex.test(a.last_name?.toLowerCase());
                const bIsArabic = arabicRegex.test(b.last_name?.toLowerCase());

                if (currentLastNameSort === 'descend') {
                    if (a.last_name === null || a.last_name == "") return 1;
                    if (b.last_name === null || b.last_name == "") return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.last_name.toLowerCase().localeCompare(b.last_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }

                if (currentLastNameSort === 'ascend') {
                    if (a.last_name === null) return 1;
                    if (b.last_name === null) return -1;
                    if (aIsArabic && !bIsArabic) return 1;
                    if (!aIsArabic && bIsArabic) return -1;
                    return a.last_name.toLowerCase().localeCompare(b.last_name?.toLowerCase(), 'ar', {sensitivity: 'base'});
                }


                return 0; // No sorting
            },
            sortOrder: currentLastNameSort,
            sortDirections: ['ascend', 'descend', null],
            onHeaderCell: () => ({
                onClick: () => {
                    if (currentLastNameSort === 'ascend') {
                        setCurrentLastNameSort('descend');
                    } else if (currentLastNameSort === 'descend') {
                        setCurrentLastNameSort(null);
                    } else {
                        setCurrentLastNameSort('ascend');
                    }
                },
            }),
        },
        {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
            sorter: (a, b) => {
                const valueA = a.phone ?? "zzzzzz";
                const valueB = b.phone ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => {
                const valueA = a.email ?? "zzzzzz";
                const valueB = b.email ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Total Booking",
            dataIndex: "total_booking",
            key: "total_booking",
            sorter: (a, b) => {
                const valueA = a.total_booking ?? Infinity;
                const valueB = b.total_booking ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Total Paid Amount",
            dataIndex: "total_paid_amount",
            key: "total_paid_amount",
            sorter: (a, b) => {
                const valueA = a.total_paid_amount ?? Infinity;
                const valueB = b.total_paid_amount ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Total Stayed",
            dataIndex: "total_stayd",
            key: "total_stayd",
            sorter: (a, b) => {
                const valueA = a.total_stayd ?? Infinity;
                const valueB = b.total_stayd ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: (a, b) => {
                const valueA = a.location ?? "zzzzzz";
                const valueB = b.location ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem>
                                تحت التطوير
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];

    const serializeIds = (data: any[]) => {
        return data.map((item) => {
            return {
                "Customer Number": item.id,
                "First Name": item.first_name,
                "Last Name": item.last_name,
                "Phone Number": item.phone,
                "Email": item.email,
                "Total Booking": item.total_booking,
                "Total Paid Amount": item.total_paid_amount,
                "Total Stayed": item.total_stayd,
                "Location": item.location,
            };
        });
    };




    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, "customers.xlsx");
    };
    const exportAllData = async () => {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=Customer&sort[by]=created_at&limit=10000`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "Accept-Language": "ar",
            }
        })
        const data = await response.json();
        const worksheet = XLSX.utils.json_to_sheet(serializeIds(data.data.items));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, "customers.xlsx");
    }
    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7"/>
                </div>

            ) : (
                <>
                    <div><Toaster/></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                                                <Profile2User
                                                    color="#475467"
                                                    variant="Bulk"
                                                    size={32}
                                                />

                                            </div>
                                            <div>
                                                <h1 className="text-xl">
                                                    العملاء
                                                </h1>
                                            </div>


                                        </div>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button>
                                                    تصدير
                                                </Button>

                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                <DropdownMenuSeparator/>
                                                <DropdownMenuGroup>
                                                    <DropdownMenuItem onClick={() => exportToExcel(data)}
                                                                      className="gap-2">
                                                        <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                        <span>تصدير الى Excel</span>
                                                    </DropdownMenuItem>
                                                    {/*<DropdownMenuItem>*/}
                                                    {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                    {/*  <span>تصدير الى pdf</span>*/}
                                                    {/*</DropdownMenuItem>*/}
                                                    <DropdownMenuItem onClick={() => {
                                                        try {
                                                            toast.promise(exportAllData(), {
                                                                loading: "جاري التحميل",
                                                                success: "تم تصدير البيانات بنجاح",
                                                                error: "حدث خطأ ما",
                                                            });
                                                        } catch (error) {
                                                            console.log(error);
                                                            toast.error("حدث خطأ ما");
                                                        }
                                                    }}
                                                                      className="gap-2">
                                                        <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                        <span>
                                                            تصدير  كل البيانات الى ملف اكسل
                                                        </span>
                                                    </DropdownMenuItem>



                                                </DropdownMenuGroup>

                                            </DropdownMenuContent>
                                        </DropdownMenu>

                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="mx-6 mt-6">
                            <Input
                                placeholder="
                                بحث عن العميل ب رقم الجوال او البريد الإلكتروني او الاسم الأول او الاسم الأخير او العدد الكلي للحجوزات او المبلغ المدفوع او العدد الكلي للإقامات
                            "
                                prefix={<Search/>}
                                onChange={(e) => setSearchText(e.target.value)}
                            ></Input>


                        </div>
                        <div className="mx-6 mt-6">
                            <Table<Customer>
                                columns={columns}
                                dataSource={data.filter((customer: Customer) => {
                                        return customer.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
                                            customer.last_name?.toLowerCase().includes(searchText.toLowerCase()) ||
                                            customer.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
                                            customer.email?.toLowerCase().includes(searchText.toLowerCase()) ||
                                            customer.total_booking?.toString().includes(searchText) ||
                                            customer.total_paid_amount?.toString().includes(searchText) ||
                                            customer.total_stayd?.toString().includes(searchText) ||
                                            customer.id?.toString().includes(searchText)


                                    }
                                )}

                                rowKey="id"
                                showHeader={true}
                                bordered={true}
                                sortDirections={["ascend", "descend"]}
                                showSorterTooltip={true}
                                loading={loading}
                                scroll={{x: 'max-content'}}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    showTotal: (total) => `Total ${total} items`,
                                    total: total,
                                    current: page,
                                    position: ["bottomCenter"],
                                    size: "default",
                                    itemRender: itemRender

                                }}
                                onChange={(pagination, filters, sorter, extra) => {
                                    console.log('params', pagination, filters, sorter, extra);
                                    setPage(pagination.current ?? 1)
                                    setPageSize(pagination.pageSize ?? 10)
                                    setLoading(true)
                                }}

                            />


                        </div>

                    </Content>
                </>
            )}


        </>
    )
}