import {Checkbox, Layout, message, Upload, UploadProps} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {

    Personalcard, SecurityUser,
} from "iconsax-react";

import {deleteFettcher, getFetcher, putFettcher} from "../../../API";
import {Button} from "../../../components/ui/button";

import {postFetcher} from "../../../lib/API";
import {Label} from "../../../components/ui/label";
import {Input} from "../../../components/ui/input";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../../components/ui/tabs";
import {Card, CardContent, CardHeader, CardTitle} from "../../../components/ui/card";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "../../../components/ui/alert-dialog";

const { Content } = Layout;

export default function AddNewFacility() {
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.user.token)
    const [currentTap, setCurrentTap] = useState("account")
    const [createdFacilty, setCreatedFacilty]:any = useState(null)
    const [fileList, setFileList]:any = useState([])
    const [form, setForm] = useState({
        titleAr: "",
        titleEn: "",
        icon_url: "",
        is_active: 1

    })
    const [isAdding, setIsAdding] = useState(false)
    const [facilityOptionsForm, setFacilityOptionsForm] = useState({
        titleAr: "",
        titleEn: "",
        facility_id: createdFacilty?.id

    })
    const [facilityOptions, setFacilityOptions] = useState([])
    const [facilityOptionsFormEdit, setFacilityOptionsFormEdit] = useState({
        titleAr: "",
        titleEn: "",
        facility_id: createdFacilty?.id

    })
    const [isEditing, setIsEditing] = useState(false)



    const props: UploadProps = {
        name: 'file',
        action: process.env.REACT_APP_DEV_ADMIN_API_URL + "upload/facilities",
        headers: {
            "Authorization": "Bearer " + token,
            "Accept-Language": "ar",
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setForm({...form, icon_url: info.file.response.data})
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };



    useEffect(() => {
        window.document.title = "الاعدادات"
    }, [])






    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7" />
                </div>

            ) : (
                <>
                    <div><Toaster /></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>


                        <div className="mx-4 mt-6">
                            <Card>
                                <CardHeader>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={" p-2 rounded-md"}>
                                                <SecurityUser variant="Bulk"
                                                              size="48" color="#475467"/>
                                            </div>

                                            <h1 className={"text-xl mr-2"}>
                                                اضافة مرفق جديد
                                            </h1>
                                        </div>

                                        <div>
                                            <Button onClick={async () => {
                                                if (createdFacilty) {
                                                    navigate("/admin/system/settings")
                                                }
                                                if (!form.titleAr || !form.titleEn) {
                                                    toast.error("الرجاء ملئ الحقول")
                                                    return
                                                }
                                                if (form.icon_url === "") {
                                                    toast.error("الرجاء رفع الايقونة")
                                                    return
                                                } else {
                                                    const res = await postFetcher(token, process.env.REACT_APP_DEV_ADMIN_API_URL + "facilities", {
                                                        titleAr: form.titleAr,
                                                        titleEn: form.titleEn,
                                                        icon_url: form.icon_url,
                                                        is_active: form.is_active

                                                    })
                                                    if (res) {
                                                        console.log(res)
                                                        toast.success("تم اضافة المرفق بنجاح")
                                                        setCreatedFacilty(res.facility)
                                                    } else {
                                                        toast.error("حدث خطأ اثناء اضافة المرفق")
                                                        setLoading(false)
                                                    }
                                                    }

                                            }}>
                                                {createdFacilty ? "انتهاء" : "اضافة"}
                                            </Button>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>

                        </div>
                        <Card style={{ direction: "rtl" }}  className="mx-4 mt-6">
                            <Tabs style={{ direction: "rtl" }} defaultValue="account" className="w-full rtl">
                                <TabsList className="grid w-full grid-cols-2">
                                    <TabsTrigger value="account">
                                        البيانات الاساسية
                                    </TabsTrigger>
                                    {createdFacilty && (
                                        <TabsTrigger value="password">
                                           اعدادات المرافق
                                        </TabsTrigger>
                                    )}
                                </TabsList>
                                <TabsContent value="account">
                                    <Card style={{ direction: "rtl" }}>
                                        <CardHeader>
                                            <CardTitle>
                                                <h1>المعلومات الاساسية</h1>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardContent className="grid grid-cols-2 gap-4">
                                            <div className="w-1/2">
                                                <Label>الاسم بالعربية</Label>
                                                <Input value={form.titleAr} onChange={(e) => setForm({...form, titleAr: e.target.value})} />
                                            </div>
                                            <div className="w-1/2">
                                                <Label>الاسم بالانجليزية</Label>
                                                <Input value={form.titleEn} onChange={(e) => setForm({...form, titleEn: e.target.value})} />
                                            </div>
                                            <div className="w-1/2 gap-4">
                                                <Upload
                                                    {...props}
                                                    fileList={fileList}
                                                    onChange={(info) => {
                                                        setFileList(info.fileList)
                                                        if (info.file.status === 'done') {
                                                            setForm({...form, icon_url: info.file.response.data})
                                                        }
                                                    }}
                                                    onRemove={(file) => {
                                                        setFileList([]);
                                                        if (file.url === form.icon_url) {
                                                            setForm({...form, icon_url: ""});
                                                        }
                                                    }}

                                                    multiple={false}

                                                >
                                                    <Button>
                                                        رفع الايقونة
                                                    </Button>
                                                </Upload>


                                            </div>
                                            <div className="w-1/2 gap-4">
                                                <Label className="mx-2">الحالة</Label>
                                                <Checkbox onChange={(e) => setForm({...form, is_active: e.target.checked ? 1 : 0})} />
                                            </div>
                                        </CardContent>

                                    </Card>
                                </TabsContent>
                                {createdFacilty && (
                                    <TabsContent value="password">
                                        <Card className="p-6" style={{ direction: "rtl" }}>
                                            <CardHeader>
                                                <CardTitle>
                                                    <h1>اعدادات المرافق</h1>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardContent className="gap-4">
                                                <Button onClick={() => {
                                                    setIsAdding(true)
                                                }}>
                                                    اضافة خيارات للمرفق
                                                </Button>
                                                <div className="mt-6">
                                                    <h1>
                                                        الخيارات المتاحة
                                                    </h1>
                                                    <div className="grid grid-cols-1">
                                                        {facilityOptions.map((option:any) => {
return (
    <div className="flex items-center justify-between mt-4">
        <div>
            <h1>
                {option.title}
            </h1>
        </div>
        <div className="gap-4 flex items-center">
            <Button onClick={async () => {
                const deleteRequest = await deleteFettcher(token, "facility-options",option.id)
                if (deleteRequest) {
                    toast.success("تم الحذف بنجاح")
                    const getFacilityOptions = await getFetcher(token, "facility-options?facilityId=" + createdFacilty.id,true
                    )
                    if (getFacilityOptions) {
                        setFacilityOptions(getFacilityOptions.facilityOptions)
                    }
                }



            }
            }>
                حذف
            </Button>
            <Button onClick={() => {
                const titleArTranslation = option.translation.find((translation: { language: { code: string; }; key: string; }) => translation.language.code === 'ar' && translation.key === 'title');
                const titleEnTranslation = option.translation.find((translation: { language: { code: string; }; key: string; }) => translation.language.code === 'en' && translation.key === 'title');
                setFacilityOptionsFormEdit({
                    titleAr: titleArTranslation.value,
                    titleEn: titleEnTranslation.value,
                    facility_id: createdFacilty.id
                })
                setIsEditing(true)

            }}>
                تعديل
            </Button>
        </div>
        <AlertDialog open={isEditing}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>تعديل فئة</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogDescription>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Label>
                                العنوان بالعربي
                            </Label>
                            <Input value={facilityOptionsFormEdit.titleAr} onChange={(event)=>{
                                setFacilityOptionsFormEdit({...facilityOptionsFormEdit, titleAr: event.target.value})
                            }}></Input>
                        </div>
                        <div>
                            <Label>
                                العنوان بالانقليزي
                            </Label>
                            <Input value={facilityOptionsFormEdit.titleEn} onChange={(event)=>{
                                setFacilityOptionsFormEdit({...facilityOptionsFormEdit, titleEn: event.target.value})

                            }}></Input>
                        </div>
                    </div>
                </AlertDialogDescription>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={() => {
                        setIsEditing(false)

                    }}>الغاء</AlertDialogCancel>
                    <AlertDialogAction onClick={async () => {

                        const putRequest = await putFettcher(token, `facility-options/${option.id}`, {
                            titleAr: facilityOptionsFormEdit.titleAr,
                            titleEn: facilityOptionsFormEdit.titleEn,
                            facilityId: createdFacilty?.id
                        })
                        if (putRequest) {
                            toast.success("تم التعديل بنجاح")
                            setIsEditing(false)
                            const getFacilityOptions = await getFetcher(token, "facility-options?facilityId=" + createdFacilty.id, true)

                            if (getFacilityOptions) {
                                setFacilityOptions(getFacilityOptions.facilityOptions)
                            }
                        }
                    }
                    }>
                        تعديل
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>

    </div>



                                                            )
                                                        }
                                                        )}
                                                    </div>


                                                </div>
                                            </CardContent>


                                        </Card>
                                    </TabsContent>
                                )}
                            </Tabs>



                        </Card>
                        <AlertDialog open={isAdding}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>اضافة فئة جديدة</AlertDialogTitle>
                                </AlertDialogHeader>
                                <AlertDialogDescription>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <Label>
                                                العنوان بالعربي
                                            </Label>
                                            <Input onChange={(event)=>{
                                                setForm({...form, titleAr: event.target.value})
                                            }}></Input>
                                        </div>
                                        <div>
                                            <Label>
                                                العنوان بالانقليزي
                                            </Label>
                                            <Input onChange={(event)=>{
                                                setForm({...form, titleEn: event.target.value})

                                            }}></Input>
                                        </div>
                                    </div>
                                </AlertDialogDescription>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => {
                                        setIsAdding(false)

                                    }}>الغاء</AlertDialogCancel>
                                    <AlertDialogAction onClick={async () => {

                                            const putRequest = await postFetcher(token, process.env.REACT_APP_DEV_ADMIN_API_URL + "facility-options", {
                                                titleAr: form.titleAr,
                                                titleEn: form.titleEn,
                                                facilityId: createdFacilty?.id
                                            })
                                            if (putRequest) {
                                                toast.success("تمت الاضافة بنجاح")
                                                setIsAdding(false)
                                                const getFacilityOptions = await getFetcher(token, "facility-options?facilityId=" + createdFacilty?.id,true)
                                                if (getFacilityOptions) {
                                                    setFacilityOptions(getFacilityOptions.facilityOptions)
                                                }

                                            } else {
                                                toast.error("حدث خطأ ما")
                                            }


                                    }}>
                                        اضافة
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>


                    </Content>
                </>
            )}


        </>
    )
}