import {Card, Layout, Select} from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardHeader,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

import { HashLoader, PropagateLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import {
  User,
  UserOctagon,
  TransactionMinus,
  TableDocument,
} from "iconsax-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Icon } from "@iconify/react";

import { setIsAuth, setUserToken } from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import * as XLSX from "xlsx";
import {cn} from "../../../lib/utils";
import {Popover, PopoverContent, PopoverTrigger} from "../../../components/ui/popover";
import {CalendarIcon} from "lucide-react";
import {addDays, format} from "date-fns";
import {Calendar} from "../../../components/ui/calendar";
import {DateRange} from "react-day-picker";

const { Content } = Layout;

export default function Payouts() {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [currentSelected, setSelected] = useState("HOSTING");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentTransactionType, setCurrentTransactionType] = useState(null);

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: addDays(new Date(2024, 0, 20), 10),
    to: addDays(new Date(2024, 0, 20), 20),
  })
  const [selectedDateFromFilter, setSelectedDateFromFilter]:any = useState(null);
  const [selectedDateToFilter, setSelectedDateToFilter]:any = useState(null);
  useEffect(() => {
    window.document.title = "المدفوعات";
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}payouts?receiver_type=${currentSelected}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        })
        const data = await response.json();
        console.log(data)
        const users = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}users?status=${currentSelected}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        });
        const usersData = await users.json();
        setUsers(usersData.data.items)
        if (response.status == 200) {
          setLoading(false)
          setData(data.payouts);

        } else {

        }
      } catch (error) {
        toast("حدث خطأ ما", { icon: "🔥" });
        console.error(error)
      }

    }
    fetchData()

  }, [currentSelected])

  const translations: { [key: string]: string }[] = [{
    "CUSTOMER": "العملاء",
    "HOSTING": "أصحاب الوحدات السكنية",
  }];
  function exportToExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "Payouts" + '.xlsx');
  }


  const selectedTranslation = translations.find((item) => item[currentSelected]);
  const translationText = selectedTranslation ? selectedTranslation[currentSelected] : '';


  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction: "rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl">المدفوعات ( {translationText} )</h1>
                    <div className="flex items-center gap-4">
                      <Button onClick={() => {
                        navigate("/admin/payouts/add", {state: currentSelected})

                      }}>
                        اضافة مدفوعات جديدة
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Icon
                              width={24}
                              height={24}
                              className="cursor-pointer"
                              icon="pepicons-pop:dots-y"
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                          <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                          <DropdownMenuSeparator/>
                          <DropdownMenuGroup>
                            <DropdownMenuItem onClick={() => setSelected("HOSTING")}>
                              <User
                                  color="#475467"
                                  variant="Bulk"
                                  className="ml-2 h-8 w-8"
                              />
                              <span>التجار</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setSelected("CUSTOMER")}>
                              <UserOctagon
                                  color="#475467"
                                  variant="Bulk"
                                  className="ml-2 h-8 w-8"
                              />
                              <span>العملاء</span>

                            </DropdownMenuItem>
                            <DropdownMenuItem className="gap-2" onClick={() => exportToExcel()}>
                              <Icon width={32} height={32} icon="vscode-icons:file-type-excel"/>
                              <span>تصدير الى أكسل</span>
                            </DropdownMenuItem>


                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>

                  </div>
                </CardHeader>
              </Card>
            </div>
            <div
                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 gap-4 mx-6 mt-6">

              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب التاريخ
                </h1>
                <div className={cn("grid gap-2")}>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                          id="date"
                          variant={"outline"}
                          className={cn(
                              "w-[300px] justify-start text-left font-normal",
                              !date && "text-muted-foreground"
                          )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4"/>
                        {date?.from ? (
                            date.to ? (
                                <>
                                  {format(date.from, "LLL dd, y")} -{" "}
                                  {format(date.to, "LLL dd, y")}
                                </>
                            ) : (
                                format(date.from, "LLL dd, y")
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                          initialFocus
                          mode="range"
                          defaultMonth={
                            date?.from ? new Date(date.from) : new Date()
                          }
                          selected={date}
                          onSelect={(newDate: any) => {
                            setDate(newDate)
                            console.log(newDate)
                            if (newDate?.from && newDate?.to) {
                              setSelectedDateFromFilter(format(newDate?.from, "y-MM-dd"))
                              setSelectedDateToFilter(format(newDate?.to, "y-MM-dd"))
                              console.log(selectedDateFromFilter, selectedDateToFilter)
                            }
                            // selectedDateFromFilter(format(newDate.from, "LLL dd, y"))
                            // selectedDateToFilter(format(newDate.to, "LLL dd, y"))


                          }
                          }
                          numberOfMonths={2}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب العميل
                </h1>
                <Select
                    placeholder="العميل"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      console.log(value)
                      setSelectedUser(value)
                    }
                    }
                >
                  {users.map((user: any) => (
                      <Select.Option key={user.phone} value={user.phone}>
                        {user.phone}
                      </Select.Option>
                  ))}
                </Select>

              </div>
              <div className="flex items-center gap-2">
                <h1 className="whitespace-nowrap">
                  فلتره حسب نوع العملية
                </h1>
                <Select
                    placeholder="نوع العملية"
                    className="w-full"
                    allowClear
                    showSearch
                    onChange={(value: any) => {
                      console.log(value)
                      setCurrentTransactionType(value)
                    }
                    }
                >
                  <Select.Option key={"BANK"} value={"BANK"}>
                    بنكية
                  </Select.Option>
                  <Select.Option key={"CASH"} value={"CASH"}>
                    كاش
                  </Select.Option>

                </Select>

              </div>
            </div>
            <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 mx-6 mt-6">
              {
                data
                    .filter((item: any) => {
                      if (selectedDateFromFilter && selectedDateToFilter) {
                        return item.created_at >= selectedDateFromFilter && item.created_at <= selectedDateToFilter
                      }
                      if (selectedUser) {
                        return item.receiver?.phone == selectedUser
                      }
                      return item
                    })
                    .filter((item: any) => {
                      return item.receiver_type == currentSelected
                    })
                    .filter((item: any) => {
                      if (currentTransactionType) {
                        return item.type == currentTransactionType
                      }
                      return item
                    })
                    .map((item: any) => (


                        <Card className="w-full">
                          <div className="flex items-center my-1 justify-between">
                            <div className="flex items-center">
                              <div
                                  style={{background: "#f9f9f9"}}
                                  className={" p-2 rounded-md"}
                              >
                                <img
                                    width={64}
                                    height={64}
                                    src="https://tulip-inn-riyadh.goldentulip.com/static/images/TI/logo-new-him.svg"
                                    alt=""
                                />
                              </div>

                              <h1 className={"text-xl mr-2"}>{item.receiver?.name}</h1>


                            </div>

                            <div>
                              <h1 className={"text-xl"}>
                                {item.amount} ر.س
                              </h1>
                            </div>
                            {/* <div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Icon
                          width={24}
                          height={24}
                          className="cursor-pointer"
                          icon="pepicons-pop:dots-y"
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                        <DropdownMenuItem>
                            <Eye
                              color="#475467"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>عرض</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => navigate("/admin/property/edit")}>
                            <Edit
                              color="#475467"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>تعديل</span>
                          </DropdownMenuItem>
                          
                          <DropdownMenuItem>
                            <Trash
                              color="#F04438"
                              variant="Bulk"
                              className="ml-2 h-8 w-8"
                            />
                            <span>حذف</span>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div> */}
                          </div>
                          <div className="flex flex-col sm:flex-row items-center justify-between gap-2 mt-6">

                            <div className="flex items-center gap-2">
                              <div
                                  style={{background: "#f9f9f9"}}
                                  className={" p-2 rounded-md"}
                              >
                                <TransactionMinus size="24" color="#475467" variant="Bulk"/>
                              </div>
                              <h4>
                                رقم العملية
                              </h4>
                              <h4>
                                {item.transaction_number}
                              </h4>
                            </div>
                            <div className="flex items-center gap-2">
                              <div
                                  style={{background: "#f9f9f9"}}
                                  className={" p-2 rounded-md"}
                              >
                                <TransactionMinus size="24" color="#475467" variant="Bulk"/>
                              </div>
                              <h4>
                                نوع العمليه
                              </h4>
                              <h4>
                                {item.type}
                              </h4>
                            </div>

                            <div className="flex items-center gap-2">
                              <div
                                  style={{background: "#f9f9f9"}}
                                  className={" p-2 rounded-md"}
                              >
                                <TableDocument size="24" color="#475467" variant="Bulk"/>
                              </div>
                              <h4>
                                وصف العملية
                              </h4>
                              <h4>
                                {item.description}
                              </h4>
                            </div>
                            <div className="flex items-center gap-2">
                              <div
                                  style={{background: "#f9f9f9"}}
                                  className={" p-2 rounded-md"}
                              >
                                <TableDocument size="24" color="#475467" variant="Bulk"/>
                              </div>
                              <h4>
                                تاريخ العملية
                              </h4>
                              <h4>
                                {item.created_at ? new Date(item.created_at).toLocaleDateString() : ''}
                              </h4>
                            </div>


                          </div>
                        </Card>
                    ))
              }

            </div>


          </Content>
        </>
      )}
    </>
  );
}
